import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SliceZone from "../components/SliceZone";

export const InstitutionalClients = ({ data }) => {
  if (!data) return null;
  const header = data.prismicHeaderitem || {};
  const footer = data.prismicFooteritem || {};
  const { lang, type, url } = header || {};
  const alternateLanguages = header.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const institutionalClient = data.prismicInstitutionalclients || {};

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={false}
    >
      <SliceZone slices={institutionalClient.data.body} />
    </Layout>
  );
};

export const query = graphql`
  query prismicInstitutionalclients($lang: String) {
    prismicInstitutionalclients(lang: { eq: $lang }) {
      lang
      url
      data {
        body {
          ... on PrismicInstitutionalclientsBodyHeroSection {
            id
            primary {
              background_image {
                url
              }
              button {
                text
              }
              button_link {
                url
              }
              subtitle {
                text
                raw
              }
              title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicInstitutionalclientsBodyLargeTestimonialSection {
            id
            slice_label
            slice_type
            primary {
              button1 {
                text
              }
              section_image {
                url
              }
              section_name {
                text
              }
              section_number {
                text
              }
              text1 {
                text
              }
              text2 {
                text
              }
              text_button {
                text
              }
              button_link {
                url
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicInstitutionalclientsBodyNumberslidersection {
            id
            slice_label
            slice_type
            primary {
              description {
                text
              }
              section_number {
                text
              }
              section_title {
                text
              }
              title {
                text
              }
            }
            items {
              slider_text {
                text
              }
              slide_number
            }
          }
          ... on PrismicInstitutionalclientsBodyPicturetextslidersection {
            id
            items {
              silder_title {
                text
              }
              slide_image {
                url
              }
              slide_text {
                text
              }
            }
            primary {
              description {
                text
              }
              section_number {
                text
              }
              section_title {
                text
              }
              title {
                text
              }
              picture_orientation
            }
            slice_label
            slice_type
          }
          ... on PrismicInstitutionalclientsBodyPicturebackgroundctasection {
            id
            slice_label
            slice_type
            primary {
              background_image {
                url
              }
              cta_text {
                text
              }
              title {
                text
              }
            }
          }
          ... on PrismicInstitutionalclientsBodyHeroSection {
            id
            primary {
              background_image {
                url
              }
              button {
                text
              }
              button_link {
                url
              }
              subtitle {
                text
              }
              title {
                text
              }
            }
          }
          ... on PrismicInstitutionalclientsBodyTextureSection {
            id
            primary {
              buttontext {
                text
              }
              emailtext {
                text
              }
              title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicInstitutionalclientsBodyOutstandingProducts {
            id
            primary {
              cta_text {
                text
              }
              button_link {
                url
              }
              section_number {
                text
              }
              section_title {
                text
              }
              title {
                text
              }
              background_image {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicInstitutionalclientsBodyAddedValues {
            id
            primary {
              description {
                text
              }
              image {
                url
              }
              section_number {
                text
              }
              section_title {
                text
              }
              title {
                text
              }
            }
            slice_label
            slice_type
            items {
              text {
                text
              }
            }
          }
          ... on PrismicInstitutionalclientsBodyStrategySection {
            id
            primary {
              description {
                text
              }
              section_number {
                text
              }
              section_title {
                text
              }
              title {
                text
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
  }
`;

export default InstitutionalClients;
